import React, { useEffect } from 'react';

const Process = () => {
  useEffect(() => {
    const reveal = () => {
      const reveals = document.querySelectorAll(".step");
      for (let i = 0; i < reveals.length; i++) {
        const windowHeight = window.innerHeight;
        const elementTop = reveals[i].getBoundingClientRect().top;
        const elementVisible = 150;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("sr-visible");
        } else {
          reveals[i].classList.remove("sr-visible");
        }
      }
    };

    window.addEventListener("scroll", reveal);
    reveal();

    return () => window.removeEventListener("scroll", reveal);
  }, []);

  return (
    <section id="process" className="process">
      <div className="container">
        <h2>Our Process</h2>
        <div className="row">
          <div className="col-md-12 step">
            <h3>Consultation</h3>
            <p>We start with a thorough consultation to understand your requirements and goals. This helps us tailor our approach to meet your specific needs.</p>
          </div>
          <div className="col-md-12 step">
            <h3>Development</h3>
            <p>Our team of experts then works on developing your project, ensuring high quality and efficiency. We keep you updated throughout the process.</p>
          </div>
          <div className="col-md-12 step">
            <h3>Review and Alterations</h3>
            <p>We present the completed project to you and gather your feedback. Any necessary alterations are made to ensure the final product meets your expectations.</p>
          </div>
          <div className="col-md-12 step">
            <h3>Deployment</h3>
            <p>Once you're satisfied with the project, we handle the deployment, making sure everything runs smoothly. We also offer ongoing support to keep your project up-to-date.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Process;

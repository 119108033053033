import React from 'react';
import backgroundVideo from '../assets/protonvideo.mp4';

const Hero = () => {
  return (
    <section className="hero">
      <video id="heroVideo" autoPlay muted loop playsInline>
        <source src={backgroundVideo} type="video/mp4" />
      </video>
      <div className="hero-content">
        <h1>Proton Technologies</h1>
        <p>Delivering High-Quality Web Solutions Quickly and Efficiently</p>
        <a href="#services" className="btn btn-lg btn-primary custom-btn">Our Services</a>
      </div>
    </section>
  );
};

export default Hero;

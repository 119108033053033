import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button, Alert } from 'react-bootstrap';


const Contact = () => {
  const { register, handleSubmit, formState: { errors, isSubmitting }, reset } = useForm();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  useEffect(() => {
    if (formSubmitted) {
      const timer = setTimeout(() => setFormSubmitted(false), 3000);
      return () => clearTimeout(timer);
    }
  }, [formSubmitted]);

  const onSubmit = async (data) => {
    try {
      const response = await fetch('https://formspree.io/f/mnnaqaoz', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setFormSubmitted(true);
        setSubmitError(false);
        reset();
      } else {
        throw new Error('Form submission failed');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitError(true);
    }
  };

  return (
    <section id="contact">
      <div className="container">
        <h2 className="section-title">Contact Us</h2>
        {formSubmitted && <Alert variant="success">Form was successfully sent!</Alert>}
        {submitError && <Alert variant="danger">There was an error sending your message. Please try again later.</Alert>}
        <Form noValidate onSubmit={handleSubmit(onSubmit)} className="custom-form">
          <Form.Group className="mb-3">
            <Form.Label htmlFor="formName" className="form-label">Name</Form.Label>
            <Form.Control
              id="formName"
              type="text"
              {...register("name", { required: "Name is required" })}
              isInvalid={!!errors.name}
              className="form-control"
            />
            <Form.Control.Feedback type="invalid" className="invalid-feedback">
              {errors.name?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="formEmail" className="form-label">Email</Form.Label>
            <Form.Control
              id="formEmail"
              type="email"
              {...register("email", {
                required: "Email is required",
                pattern: { value: /^\S+@\S+$/i, message: "Invalid email address" },
              })}
              isInvalid={!!errors.email}
              className="form-control"
            />
            <Form.Control.Feedback type="invalid" className="invalid-feedback">
              {errors.email?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="formService" className="form-label">Service</Form.Label>
            <Form.Select
              id="formService"
              {...register("service", { required: "Please select a service" })}
              isInvalid={!!errors.service}
              className="form-select"
            >
              <option value="">[ Please Select ]</option>
              <option value="web development">Web Development</option>
              <option value="ui/ux design">UI/UX Design</option>
              <option value="consultancy">Consultancy</option>
              <option value="application development">Application Development</option>
              <option value="other">Other</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid" className="invalid-feedback">
              {errors.service?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="formMessage" className="form-label">Message</Form.Label>
            <Form.Control
              id="formMessage"
              as="textarea"
              style={{ height: '100px' }}
              {...register("message", { required: "Message is required" })}
              isInvalid={!!errors.message}
              className="form-control"
            />
            <Form.Control.Feedback type="invalid" className="invalid-feedback">
              {errors.message?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Button type="submit" className="btn-lg btn-primary custom-btn" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </Button>
        </Form>
      </div>
    </section>
  );
};

export default Contact;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FaCode, FaPaintBrush, FaChalkboardTeacher, FaCogs } from 'react-icons/fa';

const Services = () => {
  return (
    <section id="services">
      <div className="container">
        <h2>Our Services</h2>
        <Row>
          <Col md={6} lg={6} className="mb-4">
            <Card>
              <Card.Body>
                <FaCode className="service-icon" />
                <Card.Title>Web Development</Card.Title>
                <Card.Text>Building modern and responsive websites using the latest technologies. From initial concept to final deployment, we ensure your website is user-friendly, fast, and secure.</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={6} className="mb-4">
            <Card>
              <Card.Body>
                <FaPaintBrush className="service-icon" />
                <Card.Title>UI/UX Design</Card.Title>
                <Card.Text>Creating user-friendly and visually appealing designs that enhance user experience. We focus on intuitive design principles to make sure your users have the best experience possible.</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={6} className="mb-4">
            <Card>
              <Card.Body>
                <FaChalkboardTeacher className="service-icon" />
                <Card.Title>Consultancy</Card.Title>
                <Card.Text>Providing expert advice to help you choose the right technology stack and strategy for your projects. Our consultancy services ensure that your project is set up for success from the start.</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={6} className="mb-4">
            <Card>
              <Card.Body>
                <FaCogs className="service-icon" />
                <Card.Title>Application Development</Card.Title>
                <Card.Text>Developing custom applications tailored to your business needs. We build scalable and maintainable applications that grow with your business.</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Services;

import React from 'react';

const Footer = () => {
  return (
    <footer className='text-center p-5'>
      <p>&copy; 2024 Proton Technologies.</p>
    </footer>
  );
};

export default Footer;

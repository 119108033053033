import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import logo from '../assets/logo.png';

const Header = () => {
  return (
    <header>
      <Navbar expand="lg" style={{ backgroundColor: '#000', position: 'sticky', top: '0', zIndex: '1000', width: '100%' }}>
        <Navbar.Brand href="#" style={{ color: 'white' }}>
          <img src={logo} alt="Proton Technologies" style={{ width: '40px', marginRight: '10px' }} />
          Proton Technologies
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarNav" className="navbar-toggler">
          <span className="navbar-toggler-icon" style={{ color: 'white' }}></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="navbarNav">
          <Nav className="ms-auto">
            <Nav.Link href="#about" style={{ color: 'white' }}>About</Nav.Link>
            <Nav.Link href="#services" style={{ color: 'white' }}>Services</Nav.Link>
            <Nav.Link href="#process" style={{ color: 'white' }}>Process</Nav.Link>
            <Nav.Link href="#testimonials" style={{ color: 'white' }}>Testimonials</Nav.Link>
            <Nav.Link href="#contact" style={{ color: 'white' }}>Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default Header;

import React from 'react';
import { Carousel, Card } from 'react-bootstrap';

const Testimonials = () => {
  return (
    <section id="testimonials" className="testimonials">
      <div className="container">
        <h2>Testimonials</h2>
        <Carousel indicators={true} interval={null}>
          <Carousel.Item>
            <Card className="h-100">
              <Card.Body>
                <p className="card-text">"Proton Technologies delivered our project ahead of schedule with outstanding quality. Our new website has helped us attract more clients and grow our business."</p>
                <p>- Construction Client</p>
              </Card.Body>
            </Card>
          </Carousel.Item>
          <Carousel.Item>
            <Card className="h-100">
              <Card.Body>
                <p className="card-text">"Their adaptability and expertise ensured our project met all our evolving requirements. The website they developed has significantly boosted our online presence."</p>
                <p>- Electrical Engineering Client</p>
              </Card.Body>
            </Card>
          </Carousel.Item>
          <Carousel.Item>
            <Card className="h-100">
              <Card.Body>
                <p className="card-text">"The team at Proton Technologies went above and beyond to deliver a fantastic product. Their work has greatly improved our customer engagement."</p>
                <p>- Plumbing Client</p>
              </Card.Body>
            </Card>
          </Carousel.Item>
          <Carousel.Item>
            <Card className="h-100">
              <Card.Body>
                <p className="card-text">"High-quality web solutions delivered quickly and efficiently. Our new application has streamlined our operations and improved our efficiency."</p>
                <p>- Accounting Client</p>
              </Card.Body>
            </Card>
          </Carousel.Item>
          <Carousel.Item>
            <Card className="h-100">
              <Card.Body>
                <p className="card-text">"Proton Technologies provided excellent service and the website has been a tremendous asset to our firm. It has improved our visibility and client interactions."</p>
                <p>- Legal Client</p>
              </Card.Body>
            </Card>
          </Carousel.Item>
          <Carousel.Item>
            <Card className="h-100">
              <Card.Body>
                <p className="card-text">"Their expertise and commitment to quality have made a significant impact on our business. The website has attracted new clients and provided a professional image."</p>
                <p>- Architectural Client</p>
              </Card.Body>
            </Card>
          </Carousel.Item>
        </Carousel>
      </div>
    </section>
  );
};

export default Testimonials;

import React from 'react';

const About = () => {
  return (
    <section id="about">
      <div className="container">
        <h2>About Us</h2>
        <p>
          At Proton Technologies, we specialize in crafting high-quality web applications tailored to meet the dynamic needs of our clients. With a broad array of skills in the latest technologies, we are equipped to deliver fast, adaptable, and efficient solutions. Our team is committed to excellence, ensuring that every project is completed to the highest standards.
        </p>
        <p>
          Whether you need a simple website or a complex web application, we have the expertise to make it happen. Our commitment to quality and speed ensures that your project will be delivered on time and exceed expectations. We believe in building long-term relationships with our clients, providing ongoing support and updates to ensure your web solutions remain cutting-edge.
        </p>
        <p>
          Our expertise spans a wide range of technologies including HTML, CSS, JavaScript, React, Node.js, and more. This versatility allows us to tailor our services to your specific needs, whether you're looking for a complete website overhaul or just a few tweaks to improve performance.
        </p>
      </div>
    </section>
  );
};

export default About;
